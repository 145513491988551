<template>
  <div>
    <navbar
      ref="navbar"
      @getScreenWidths="getScreenWidths"
      @getScrollView="getScrollView"
    ></navbar>

    <div
      class="container"
      :style="screenWidths > 820 ? '' : 'padding-top:40px;'"
    >
      <div class="topShow">
        <img class="topShowImg" src="@/assets/image/softwareOutsourc/top.png" alt="" />
      </div>
      <el-row style="margin: 0; display: flex; flex-wrap: wrap">
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 24, offset: 0 }"
          :md="{ span: 24, offset: 0 }"
          :lg="{ span: 20, offset: 2 }"
          :xl="{ span: 16, offset: 4 }"
        >
          <!-- 基于通用技术框架-合作模式 -->
          <div class="title" ref="soft2">
            <div class="title-t">通用框架模式</div>
            <div class="title-b"></div>
          </div>
          <div class="steps">
            <div :class="effect1 ? 'steps-item s bounce-in-left' : 'disnone'">
              <div class="steps-item-title">01.双方评估磋商</div>
              <div class="steps-item-content">
                <p>沟通总体业务</p>
                <p>评估各类风险点</p>
                <p>需求评估实施费用与周期</p>
              </div>
            </div>
            <div :class="effect1 ? 'steps-item t bounce-in-right' : 'disnone'">
              <div class="steps-item-title">02.签订项目合同</div>
              <div class="steps-item-content">
                <p>约定技术路线</p>
                <p>约定交付工期</p>
                <p>约定保密条款</p>
              </div>
            </div>
          </div>
          <!-- 基于特定开发平台-合作模式 -->
          <div class="title" ref="soft1">
            <div class="title-t">特定平台模式</div>
            <div class="title-b"></div>
          </div>

          <div class="steps">
            <div :class="effect2 ? 'steps-item l bounce-in-left' : 'disnone'">
              <div class="steps-item-title">01.双方评估磋商</div>
              <div class="steps-item-content">
                <p>评估各类风险点</p>
                <p>评估实施难易程度</p>
                <p>评估技术体系契合度</p>
              </div>
            </div>
            <div :class="effect2 ? 'steps-item e bounce-in-fwd' : 'disnone'">
              <div class="steps-item-title">02.签订框架合作协议</div>
              <div class="steps-item-content">
                <p>建立合作意向</p>
                <p>约定保密条款</p>
                <p>框架培训学习</p>
              </div>
            </div>
            <div :class="effect2 ? 'steps-item w bounce-in-right' : 'disnone'">
              <div class="steps-item-title">03.签订具体项目合同</div>
              <div class="steps-item-content">
                <p>评估各类风险点</p>
                <p>评估实施难易程度</p>
                <p>评估技术体系契合度</p>
              </div>
            </div>
          </div>

          <div class="title" ref="soft3">
            <div class="title-t">项目管理流程</div>
            <div class="title-b"></div>
          </div>
          <div class="steps" style="padding-bottom: 80px">
            <div :class="effect3 ? 'steps-item2 l bounce-in-left' : 'disnone'">
              <div class="steps-item-title">01.签订合同</div>
              <div class="steps-item-content">
                <p>技术规范</p>
                <p>保密条款</p>
                <p>实施周期</p>
              </div>
            </div>
            <div :class="effect3 ? 'steps-item2 s bounce-in-left' : 'disnone'">
              <div class="steps-item-title">02.需求开发</div>
              <div class="steps-item-content">
                <p>需求调研</p>
                <p>原型设计</p>
                <p>用户需求说明书</p>
              </div>
            </div>
            <div :class="effect3 ? 'steps-item2 t bounce-in-fwd' : 'disnone'">
              <div class="steps-item-title">03.设计编码</div>
              <div class="steps-item-content">
                <p>概要设计</p>
                <p>数据库设计</p>
                <p>编码规范</p>
                <p>接口设计</p>
              </div>
            </div>
          </div>
          <div class="steps" style="padding-bottom: 80px">
            <div :class="effect3 ? 'steps-item2 e bounce-in-fwd' : 'disnone'">
              <div class="steps-item-title">04.测试上线</div>
              <div class="steps-item-content">
                <p>功能测试</p>
                <p>性能测试</p>
                <p>安全测试</p>
                <p>上线报告</p>
              </div>
            </div>
            <div :class="effect3 ? 'steps-item2 w bounce-in-right' : 'disnone'">
              <div class="steps-item-title">05.试运行验收</div>
              <div class="steps-item-content">
                <p>试运行优化</p>
                <p>验收报告</p>
              </div>
            </div>
            <div :class="effect3 ? 'steps-item2 y bounce-in-right' : 'disnone'">
              <div class="steps-item-title">06.维保</div>
              <div class="steps-item-content">
                <p>远程技术支持</p>
                <p>现场人员保障</p>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import navbar from "../../components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      screenWidths: 0,
      effect1: true,
      effect2: false,
      effect3: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    getScreenWidths(val) {
      this.screenWidths = val;
    },
    // 滚动条
    getScrollView(id) {
      if (id == 31) {
        this.$refs.soft1.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 32) {
        this.$refs.soft2.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 33) {
        this.$refs.soft3.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },
    onScroll() {
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrolled > 40) {
        this.effect2 = true;
      }
      if (scrolled > 200) {
        this.effect3 = true;
      }
      if (this.screenWidths < 820) {
        this.effect2 = true;
         this.effect3 = true;
      }
    },
  },
};
</script>

<style  scoped>
.disnone {
  display: none;
}

.bounce-in-fwd {
  -webkit-animation: bounce-in-fwd 1.1s both;
  animation: bounce-in-fwd 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 12:49:4
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(0.84);
    transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(0.84);
    transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.bounce-in-left {
  -webkit-animation: bounce-in-left 1.1s both;
  animation: bounce-in-left 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 12:47:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.bounce-in-right {
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 12:39:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.topShow {
  width: 100%;
  overflow: hidden;
}
.topShowImg {
  width: 100%;
}

.title {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  margin-top: 0px;
}
.title-t {
  color: #333;
  font-size: 26px;
  margin-bottom: 16px;
  position: relative;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
}
.title-t::before,
.title-t::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 50%;
  width: 180px;
  height: 180px;
  border-radius: 25% 65% 43% 47%;
  transform: translate(-50%, -50%);
  background: rgba(3, 169, 244, 0.85);
  animation: rotate 10s infinite linear;
  z-index: 1;
  mix-blend-mode: lighten;
}
.title-t::after {
  border-radius: 43% 47% 44% 48%;
  animation: rotate 10s infinite 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.title-b {
  width: 60px;
  height: 2px;
  background-color: rgb(44, 134, 237);
}

/* 步骤条 */
.steps {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.steps-item {
  width: 210px;
  height: 190px;
  padding: 32px;
  box-shadow: 0 0 16px rgba(165, 165, 165, 0.4);
  text-align: center;
  /* background-color: #99ccff; */
  margin-bottom: 32px;
  border-radius: 8px;
}

.steps-item2 {
  width: 160px;
  height: 150px;
  padding: 32px 16px;
  box-shadow: 0 0 16px rgba(165, 165, 165, 0.4);
  text-align: center;
  /* background-color: #99ccff; */
  margin-bottom: 32px;
  border-radius: 8px;
}

.steps-item2 .steps-item-content {
  width: 100%;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.steps-item-title {
  width: 100%;
  line-height: 60px;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
}
.steps-item-content {
  width: 100%;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  text-align: left;
}

.l {
  background-color: #6699cc;
}
.s {
  background-color: #2f99ce;
}

.t {
  background-color: #178fb8;
}
.e {
  background-color: #006699;
}
.w {
  background-color: #336699;
}
.y {
  background-color: #666699;
}
</style>